import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAnketaTagProps, getProfileTagProps } from '@resources/anketaState'
import dayjs from 'dayjs'

import { ProfileStatus, editableProfileStatuses } from '@dltru/dfa-models'
import {
    Alert,
    Box,
    Button,
    Divider,
    RUS_DATETIME_SEC_FORMAT,
    RoleFormCard,
    Space,
    Tag,
    getFullName,
} from '@dltru/dfa-ui'

import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import { RoleFormContext } from '@components/Layout/RoleFormsLayout'

import { getAlertText, getAlertType } from '../../helper'

import { ApproveAnketaModal } from '../ApproveAnketaModal'
import '../style.less'

import { BeforeSendingView } from './BeforeSendingView'
import { identificationDescription } from './resources'

export const AfterSendingView: FC = () => {
    const { option, setOption, ownInterests, clientInterests } = useContext(RoleFormContext)
    const userFullName = useSelector(roleFormSelector.userFullName)
    const profile = useSelector(roleFormSelector.selectProfile)
    const profileType = useSelector(roleFormSelector.selectProfileType)
    const profileAgent = useSelector(roleFormSelector.selectAgentAnketa)
    const mainStatus = useSelector(roleFormSelector.selectMainStatus)
    const [isModalShown, setIsModalShown] = useState(false)
    const reduxDispatch = useDispatch()
    const approvalHistory = useSelector(profileSelector.selectApprovalHistory)

    useEffect(() => {
        if (profileType) {
            const interests = profileAgent ? clientInterests : ownInterests
            const selectedOption = interests.find((value) => value.typeShort === profileType)
            if (selectedOption) {
                setOption(selectedOption)
            }
        }
    }, [profileType, profileAgent, ownInterests, setOption, clientInterests])

    useEffect(() => {
        if (approvalHistory.length === 0) {
            reduxDispatch(profileDetailsSlice.actions.getApprovalHistory())
        }
        if (!profile) {
            reduxDispatch(roleFormsSlice.actions.getProfiles())
        }
    }, [])

    if (!mainStatus) {
        return null
    }

    const mainProfileTag = getProfileTagProps(mainStatus)

    const sendWithAgent =
        profileAgent?.uuid &&
        profileAgent.application_status &&
        editableProfileStatuses.includes(profileAgent.application_status)

    const sendWithClient =
        profile?.profile_status && editableProfileStatuses.includes(profile.profile_status)

    const changeAnketaStatus = () => {
        if (profileType) {
            reduxDispatch(
                roleFormsSlice.actions.changeStatusAnketa({
                    type: sendWithClient ? profileType : undefined,
                    agent_uuid: profileAgent?.uuid ?? undefined,
                    user_status: profile?.profile_status,
                    agent_status: profileAgent?.application_status,
                }),
            )

            setIsModalShown(false)
        }
    }

    const tagPropsAgent = profileAgent?.application_status
        ? getAnketaTagProps(profileAgent.application_status)
        : undefined
    const tagPropsClient = profile?.profile_status
        ? getAnketaTagProps(profile.profile_status)
        : undefined

    return (
        <>
            <Box className="formbox" width="100%" padding={24} margin={[0, 0, 16, 0]}>
                <Space>
                    <h2 className="box-header">Регистрация</h2>
                    <Tag color={mainProfileTag.color}>{mainProfileTag.title}</Tag>
                </Space>
                {Boolean(approvalHistory[0]?.approval_datetime) && (
                    <span>
                        {dayjs
                            .unix(approvalHistory[0].approval_datetime)
                            .format(RUS_DATETIME_SEC_FORMAT)}
                    </span>
                )}

                <Box padding={[24, 0, 16, 0]} direction="row">
                    <Space size={16} align="start">
                        <RoleFormCard
                            title="Анкета пользователя"
                            subtitle={userFullName ?? ''}
                            isCompleted
                            to={option?.link}
                            tag={
                                tagPropsClient ? (
                                    <Tag color={tagPropsClient.color}>{tagPropsClient.title}</Tag>
                                ) : undefined
                            }
                        />
                        {profileAgent && (
                            <RoleFormCard
                                title="Анкета представителя"
                                subtitle={getFullName(profileAgent)}
                                isCompleted
                                to="client-representative"
                                tag={
                                    tagPropsAgent ? (
                                        <Tag color={tagPropsAgent.color}>{tagPropsAgent.title}</Tag>
                                    ) : undefined
                                }
                            />
                        )}
                    </Space>
                </Box>

                {mainStatus === ProfileStatus.IDENTIFICATION && profileType ? (
                    <Alert
                        showIcon
                        type="info"
                        message="Требуется проведение личной идентификации"
                        description={identificationDescription[profileType]}
                    />
                ) : (
                    <Alert
                        description={getAlertText(mainStatus)}
                        type={getAlertType(mainStatus)}
                        showIcon
                    />
                )}
                {mainStatus === ProfileStatus.REWORK && (
                    <>
                        <Divider margin={[16, 0, 0, 0]} />
                        <ApproveAnketaModal
                            visible={isModalShown}
                            onCancel={() => setIsModalShown(false)}
                            onSubmit={changeAnketaStatus}
                            noClientRepresentative={!sendWithAgent}
                            noMainAnketa={!sendWithClient}
                            withoutAgreements
                        />
                        <Box direction="row" padding={[16, 0]}>
                            <Button
                                type="primary"
                                borderRadius={12}
                                // disabled={!isSendButtonEnabled}
                                onClick={() => setIsModalShown(true)}
                            >
                                Отправить на проверку
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            {(mainStatus === ProfileStatus.REJECTED || mainStatus === ProfileStatus.ARCHIVED) && (
                <BeforeSendingView />
            )}
        </>
    )
}
