import { ProfileType } from '@dltru/dfa-models'

const HotLine = () => <a href="tel:84951207542"> 7 (495) 120-75-42 </a>

export const identificationDescription = {
    [ProfileType.LEGL]: (
        <>
            <p>
                Уважаемый Заявитель, для подтверждения Вашей личности Вам необходимо пройти
                процедуру идентификации. Предоставляемые на идентификации документы должны совпадать
                с загруженными документами. В ближайшее время с вами свяжется специалист для
                согласования встречи. Телефон для связи - <HotLine />.
            </p>
            Список документов, оригиналы или нотариально заверенные копии, которые необходимо иметь
            при себе (
            <a href="/static/files/ul-list.txt" download>
                скачать список документов
            </a>
            ):
            <ol>
                <li>
                    Анкета клиента – юридического лица, заверенная печатью и подписью руководителя
                    Организации;
                </li>
                <li>Свидетельство о государственной регистрации;</li>
                <li>Свидетельство о постановке юридического лица на налоговый учет;</li>
                <li>Лицензии на право осуществления деятельности, подлежащей лицензированию;</li>
                <li>
                    Учредительные документы (устав, учредительный договор – предоставляются те
                    документы, которые являются для Клиента учредительными документами в
                    соответствии с законодательством Российской Федерации);
                </li>
                <li>
                    Документ, подтверждающий полномочия единоличного исполнительного органа
                    (решение/протокол о назначении/избрании или выписка из него) Документ,
                    удостоверяющий личность единоличного исполнительного органа, Представителя,
                    Бенефициарного владельца. В случае если указанные лица являются иностранными
                    гражданами или лицами без гражданства дополнительно представляется миграционная
                    карта и (или) документ, подтверждающий его право на пребывание (проживание) в
                    Российской Федерации;
                </li>
                <li>
                    Анкета клиента – физического лица, заполненная в отношении представителя
                    клиента, действующего от имени Организации на основании доверенности, заверенная
                    подписью представителя (при наличии такого лица);
                </li>
                <li>
                    Доверенность в случае подписания договора представителем Организации по
                    доверенности;
                </li>
                <li>
                    Сведения о деловой репутации - отзывы (в произвольной письменной форме, при
                    возможности их получения) от партнеров-других клиентов ООО «Системы
                    распределенного реестра», и (или) отзывы от финансовых организаций, где
                    Организация находится на обслуживании;
                </li>
                <li>
                    Сведения (документы) о финансовом положении (не менее одного из документов,
                    указанных далее):
                    <ul>
                        <li>
                            копия годовой бухгалтерской отчетности (бухгалтерский баланс, отчет о
                            финансовом результате) за прошедший год;
                        </li>
                        <li>
                            копия годовой (либо квартальной) налоговой декларации с отметками
                            налогового органа об их принятии или без такой отметки с приложением
                            либо копии квитанции об отправке заказного письма с описью вложения (при
                            направлении по почте), либо копии подтверждения отправки на бумажных
                            носителях (при передаче в электронном виде);
                        </li>
                        <li>
                            копия аудиторского заключения на годовой отчет за прошедший год, в
                            котором подтверждаются достоверность финансовой (бухгалтерской)
                            отчетности и соответствие порядка ведения бухгалтерского учета
                            законодательству РФ;
                        </li>
                        <li>
                            справка об исполнении налогоплательщиком (плательщиком сборов, налоговым
                            агентом) обязанности по уплате налогов, сборов, пеней, штрафов, выданная
                            налоговым органом (справка действительна в течение 30 дней);
                        </li>
                        <li>
                            данные о рейтинге, размещенные в сети «Интернет» на сайтах международных
                            рейтинговых агентств (Справочно: «Standard & Poor's», «Fitch-Ratings»,
                            «Moody's Investors Service») и национальных рейтинговых агентств);
                        </li>
                        <li>
                            сведения об отсутствии производства по делу о несостоятельности
                            (банкротстве), вступивших в силу решений судебных органов о признании
                            его несостоятельным (банкротом), проведения процедур ликвидации по
                            состоянию на дату представления документов в компанию;
                        </li>
                        <li>
                            сведения об отсутствии фактов неисполнения Организацией своих денежных
                            обязательств по причине отсутствия денежных средств на банковских
                            счетах.
                        </li>
                    </ul>
                </li>
            </ol>
        </>
    ),
    [ProfileType.PRSN]: (
        <>
            <p>
                Уважаемый Заявитель, для подтверждения Вашей личности Вам необходимо пройти
                процедуру идентификации. Предоставляемые на идентификации документы должны совпадать
                с загруженными документами. В ближайшее время с вами свяжется специалист для
                согласования встречи. Телефон для связи -
                <HotLine />. Список документов, оригиналы или нотариально заверенные копии, которые
                необходимо иметь при себе (
                <a href="/static/files/fl-list.txt" download>
                    скачать список документов
                </a>
                ):
            </p>

            <ol>
                <li>Анкета клиента – физического лица, заверенная подписью; </li>
                <li>Документ, удостоверяющие личность;</li>
                <li>
                    Анкета клиента – физического лица, заполненная в отношении представителя
                    клиента, действующего от имени клиента на основании доверенности, заверенная
                    подписью представителя (при наличии такого лица);
                </li>
                <li>
                    Доверенность и документ, удостоверяющий личность, в случае подписания договора
                    представителем по доверенности.
                </li>
            </ol>
        </>
    ),
    [ProfileType.BUSN]: (
        <>
            Уважаемый Заявитель, для подтверждения Вашей личности Вам необходимо пройти процедуру
            идентификации. Предоставляемые на идентификации документы должны совпадать с
            загруженными документами. В ближайшее время с вами свяжется специалист для согласования
            встречи. Телефон для связи - <HotLine />. Список документов, оригиналы или нотариально
            заверенные копии, которые необходимо иметь при себе (
            <a href="/static/files/ip-list.txt" download>
                скачать список документов
            </a>
            ):
            <ol>
                <li>
                    Анкета клиента – индивидуального предпринимателя (ИП), заверенная печатью (при
                    использовании) и подписью ИП;
                </li>
                <li>Свидетельство о государственной регистрации;</li>
                <li>Свидетельство о постановке на учет в налоговом органе;</li>
                <li>Документ, удостоверяющих личность ИП;</li>
                <li>Лицензии на право осуществления деятельности, подлежащей лицензированию;</li>
                <li>
                    Информация о страховом номере индивидуального лицевого счета застрахованного
                    лица в системе обязательного пенсионного страхования (при наличии) (СНИЛС);
                </li>
                <li>
                    Анкета клиента – физического лица, заполненная в отношении представителя
                    клиента, действующего от имени ИП на основании доверенности, заверенная подписью
                    представителя (при наличии такого лица);
                </li>
                <li>
                    Доверенность и документ, удостоверяющий личность, в случае подписания договора
                    представителем ИП по доверенности;
                </li>
                <li>
                    Сведения о деловой репутации - отзывы (в произвольной письменной форме, при
                    возможности их получения) от партнеров-других клиентов ООО «Системы
                    распределенного реестра», и (или) отзывы от финансовых организаций, где ИП
                    находится на обслуживании;
                </li>
                <li>
                    Сведения (документы) о финансовом положении (не менее одного из документов,
                    указанных далее):
                    <ul>
                        <li>
                            копия годовой бухгалтерской отчетности (бухгалтерский баланс, отчет о
                            финансовом результате) за прошедший год;
                        </li>
                        <li>
                            копия годовой (либо квартальной) налоговой декларации с отметками
                            налогового органа об их принятии или без такой отметки с приложением
                            либо копии квитанции об отправке заказного письма с описью вложения (при
                            направлении по почте), либо копии подтверждения отправки на бумажных
                            носителях (при передаче в электронном виде);
                        </li>
                        <li>
                            копия аудиторского заключения на годовой отчет за прошедший год, в
                            котором подтверждаются достоверность финансовой (бухгалтерской)
                            отчетности и соответствие порядка ведения бухгалтерского учета
                            законодательству РФ;
                        </li>
                        <li>
                            справка об исполнении налогоплательщиком (плательщиком сборов, налоговым
                            агентом) обязанности по уплате налогов, сборов, пеней, штрафов, выданная
                            налоговым органом (справка действительна в течение 30 дней);
                        </li>
                        <li>
                            данные о рейтинге, размещенные в сети «Интернет» на сайтах международных
                            рейтинговых агентств (Справочно: «Standard & Poor's», «Fitch-Ratings»,
                            «Moody's Investors Service») и национальных рейтинговых агентств);
                        </li>
                        <li>
                            сведения об отсутствии производства по делу о несостоятельности
                            (банкротстве), вступивших в силу решений судебных органов о признании
                            его несостоятельным (банкротом), проведения процедур ликвидации по
                            состоянию на дату представления документов в компанию;
                        </li>
                        <li>
                            сведения об отсутствии фактов неисполнения ИП своих денежных
                            обязательств по причине отсутствия денежных средств на банковских
                            счетах.
                        </li>
                    </ul>
                </li>
            </ol>
        </>
    ),
}
