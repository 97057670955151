import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAnketaTagProps } from '@resources/anketaState'
import dayjs from 'dayjs'

import { ProfileType, TAgreementData, negativeProfileStatuses } from '@dltru/dfa-models'
import { Box, Button, Divider, RoleFormCard, Space, Tag } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { roleFormsSlice } from '@store/roleForms'
import { roleFormSelector } from '@store/roleForms/selectors'

import {
    IInterest,
    InterestType,
    RoleFormContext,
    ViewState,
} from '@components/Layout/RoleFormsLayout'

import { ApproveAnketaModal } from '../ApproveAnketaModal'
import { RadioPick } from '../RadioPick'
import '../style.less'

export const BeforeSendingView: FC = () => {
    const {
        setViewState,
        interest,
        setInterest,
        option,
        setOption,
        ownInterests,
        clientInterests,
        countOfSteps,
        defaultProfile,
    } = useContext(RoleFormContext)

    const reduxDispatch = useDispatch()

    const agentProfile = useSelector(roleFormSelector.selectAgentAnketa)
    const legalProfile = useSelector(roleFormSelector.selectLegalEntityAnketa)
    const individualProfile = useSelector(roleFormSelector.selectIndividualAnketa)
    const businessmanProfile = useSelector(roleFormSelector.selectBusinessmanAnketa)
    const mainStatus = useSelector(roleFormSelector.selectMainStatus)
    const userUuid = useSelector(authSelector.selectUserUid)

    const [isModalShown, setIsModalShown] = useState(false)
    const [isSendButtonEnabled, setSendButtonEnabled] = useState(false)
    const isRejectStatus = mainStatus && negativeProfileStatuses.includes(mainStatus)

    useEffect(() => {
        if (userUuid) {
            reduxDispatch(roleFormsSlice.actions.getAgentProfileByUserId(userUuid))
        }
    }, [userUuid])

    const getProfileData = (type: ProfileType) => {
        if (!isRejectStatus) {
            switch (type) {
                case ProfileType.PRSN:
                    return individualProfile
                case ProfileType.LEGL:
                    return legalProfile
                case ProfileType.BUSN:
                    return businessmanProfile
                default:
                    return null
            }
        }
    }

    useEffect(() => {
        if (interest === InterestType.own && getProfileData(option.typeShort)?.id) {
            setSendButtonEnabled(true)
            return
        }

        if (
            interest === InterestType.client &&
            agentProfile?.id &&
            getProfileData(option.typeShort)?.id
        ) {
            setSendButtonEnabled(true)
            return
        }

        setSendButtonEnabled(false)
    }, [
        option.type,
        interest,
        agentProfile?.id,
        legalProfile?.id,
        individualProfile?.id,
        businessmanProfile?.id,
    ])

    const tagPropsAgent =
        !isRejectStatus && agentProfile?.application_status
            ? getAnketaTagProps(agentProfile.application_status)
            : undefined

    const getPropsForCard = (interestType: InterestType) => {
        const profile = interest === interestType ? option : defaultProfile[interestType]
        const profileData = getProfileData(profile.typeShort)
        const isCompleted = Boolean(profileData?.id)
        const tagProps = profileData?.profile_status
            ? getAnketaTagProps(profileData.profile_status)
            : undefined

        return {
            isCompleted,
            title: `Анкета пользователя: ${profile.forTitle}`,
            tag: tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined,
            subtitle: `Заполнено ${isCompleted ? countOfSteps[profile.typeShort] : 0} из ${
                countOfSteps[profile.typeShort]
            } шагов`,
            to: profile.link,
            isCreate: isRejectStatus || !profileData?.id,
            disabled:
                interest !== interestType ||
                (interestType === InterestType.client && !agentProfile?.id),
        }
    }

    const agentSubtitle = `Заполнено ${
        !isRejectStatus && agentProfile?.id ? countOfSteps.AGNT : 0
    } из ${countOfSteps.AGNT} шагов`

    const changeAnketaStatus = (agreements?: Partial<TAgreementData>) => {
        const callback = () => setViewState(ViewState.afterSending)

        const agreementDate = dayjs().unix()

        reduxDispatch(
            roleFormsSlice.actions.changeStatusAnketa({
                type: option.typeShort,
                agent_uuid: interest === InterestType.client ? agentProfile?.uuid : undefined,
                agreements: {
                    personal_data_agreement: agreementDate,
                    confidentiality_agreement: agreementDate,
                },
                callback,
            }),
        )
    }

    useEffect(() => {
        if (!agentProfile?.id) {
            setInterest(InterestType.own)
            if (businessmanProfile) {
                setOption(ownInterests[1])
            }

            if (individualProfile) {
                setOption(ownInterests[0])
            }

            return
        }

        setInterest(InterestType.client)
        setOption(clientInterests[0])

        if (legalProfile) {
            setOption(clientInterests[0])
        }

        if (businessmanProfile) {
            setOption(clientInterests[1])
        }

        if (individualProfile) {
            setOption(clientInterests[2])
        }
    }, [agentProfile?.id, legalProfile, businessmanProfile, individualProfile])

    return (
        <>
            <Box className="formbox" width="100%" padding={24}>
                <Box direction="row" padding={[0, 0, 20, 0]}>
                    <span className="formbox-title">Заполните заявку на регистрацию</span>
                </Box>
                <Divider />
                <Box padding={[0, 16]}>
                    <Box direction="row" padding={[32, 0]}>
                        <RadioPick
                            label="Я представляю свои интересы"
                            elements={ownInterests}
                            value={option}
                            onRadioPickChange={(value) => {
                                setInterest(InterestType.own)
                                setOption(value as IInterest)
                            }}
                        />
                        <RoleFormCard {...getPropsForCard(InterestType.own)} />
                    </Box>
                    <Divider />
                    <Box direction="row" padding={[32, 0]}>
                        <RadioPick
                            label="Я представляю интересы Пользователя"
                            elements={clientInterests}
                            value={option}
                            onRadioPickChange={(value) => {
                                setInterest(InterestType.client)
                                setOption(value as IInterest)
                            }}
                        />
                        <Space size={16} align="start">
                            <RoleFormCard
                                tag={
                                    tagPropsAgent ? (
                                        <Tag color={tagPropsAgent.color}>{tagPropsAgent.title}</Tag>
                                    ) : undefined
                                }
                                isCompleted={!isRejectStatus && Boolean(agentProfile?.id)}
                                title="Анкета представителя"
                                subtitle={agentSubtitle}
                                disabled={interest !== InterestType.client}
                                to="client-representative"
                                isCreate={isRejectStatus || !agentProfile?.id}
                            />
                            <RoleFormCard {...getPropsForCard(InterestType.client)} />
                        </Space>
                    </Box>
                    <Divider />
                    <Box padding={[32, 0]}>
                        <span>
                            Для начала работы в Личном кабинете необходимо заполнить Анкету.
                        </span>
                        {/*<span>
                            Это может занять длительное время, но вы всегда можете прерваться и
                            вернуться к заполнению с того места, где остановились - просто
                            авторизуйтесь заново.
                        </span>*/}
                    </Box>
                </Box>
                <Divider />
                <ApproveAnketaModal
                    visible={isModalShown}
                    onCancel={() => setIsModalShown(false)}
                    onSubmit={changeAnketaStatus}
                    noClientRepresentative={interest === InterestType.own}
                />
                <Box direction="row" padding={[16, 0]}>
                    <Button
                        type="primary"
                        borderRadius={12}
                        disabled={!isSendButtonEnabled}
                        onClick={() => setIsModalShown(true)}
                    >
                        Отправить на проверку
                    </Button>
                </Box>
            </Box>
        </>
    )
}
